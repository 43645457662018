import { useStaticQuery, graphql } from "gatsby"

const useRehearsalFileList = () => {
    const data = useStaticQuery(graphql`
        {
            allFile(filter: {root: {}}) {
                nodes {
                    relativePath
                }
            }
        }
    `)
    
    /** @namespace data.allFile */
    return data.allFile.nodes.reduce((coll, node) => {
        const filePathAndName = node.relativePath
        if (filePathAndName.includes('proba')) {
            coll.push(filePathAndName.substring("proba/".length))
        }
        return coll
    }, [])
}

export default useRehearsalFileList