import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import useRehearsalFileList from '../hooks/useAlbumFileList/useRehearsalFileList'
import LinkedFileList from '../components/LinkedFileList/LinkedFileList'
import * as probaStyles from '../css/proba.module.css'

const Probak = () => {
    const rehearsalFileList = useRehearsalFileList()

    const songGroups = rehearsalFileList.reduce((coll, filePathAndName) => {
        const pathParts = filePathAndName.split('/')

        coll[pathParts[0]] = coll[pathParts[0]] || []
        coll[pathParts[0]].push(pathParts[1])

        return coll
    }, {})

    return (
        <>
            <Helmet bodyAttributes={{
                class: probaStyles.body
            }}>
                <meta charSet="iso-8859-1" />
                <title>próbák, felvételek - aznap sámán trip</title>
            </Helmet>
            {
                Object.keys(songGroups).map((folderName, i) => (
                    <Fragment key={i}>
                        <br />{folderName}<br /><br />
                        <LinkedFileList
                            path="proba"
                            fileList={songGroups[folderName]}
                        />
                        <br /><br />
                    </Fragment>
                ))
            }
        </>
    )
}

export default Probak
